import type { SVGProps } from 'react'
const SvgCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.76 3.792c-.2-.2-.51-.2-.71 0l-6.95 6.95-2.89-2.89c-.2-.2-.51-.2-.71 0-.2.2-.2.51 0 .71l3.24 3.24a.485.485 0 0 0 .7 0l3.65-3.65 3.65-3.65c.2-.2.2-.51 0-.71h.02Z"
    />
  </svg>
)
export default SvgCheck

import * as LabelPrimitive from '@radix-ui/react-label'
import { Slot } from '@radix-ui/react-slot'
import * as React from 'react'
import {
  Controller,
  ControllerProps,
  FieldError,
  FieldPath,
  FieldValues,
  FormProvider,
  useFormContext,
} from 'react-hook-form'

import InputError from '@snipfeed/tint2/components/InputError'
import Label from '@snipfeed/tint2/components/InputLabel'
import { cn } from '@snipfeed/tint2/utils/className'

const Form = FormProvider

type FormFieldContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  name: TName
}

const FormFieldContext = React.createContext<FormFieldContextValue>(
  {} as FormFieldContextValue
)

interface FormFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> extends ControllerProps<TFieldValues, TName> {
  autoSubmit?: boolean
  onSubmit?: (data: TFieldValues) => void
}

let submitDebounce: number

const FormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  autoSubmit = false,
  onSubmit,
  ...props
}: FormFieldProps<TFieldValues, TName>) => {
  const { handleSubmit } = useFormContext<TFieldValues>()

  return (
    <FormFieldContext.Provider value={{ name: props.name }}>
      <Controller
        {...props}
        render={(renderProps) => {
          if (autoSubmit && onSubmit) {
            return props.render({
              ...renderProps,
              field: {
                ...renderProps.field,
                onBlur: () => {
                  renderProps.field.onBlur()
                  clearTimeout(submitDebounce)
                  submitDebounce = window.setTimeout(() => {
                    handleSubmit(onSubmit)()
                  }, 100)
                },
              },
            })
          }

          return props.render(renderProps)
        }}
      />
    </FormFieldContext.Provider>
  )
}

const useFormField = () => {
  const fieldContext = React.useContext(FormFieldContext)
  const itemContext = React.useContext(FormItemContext)
  const { getFieldState, formState } = useFormContext()

  const fieldState = getFieldState(fieldContext.name, formState)

  if (!fieldContext) {
    throw new Error('useFormField should be used within <FormField>')
  }

  const { id } = itemContext

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState,
  }
}

type FormItemContextValue = {
  id: string
}

const FormItemContext = React.createContext<FormItemContextValue>(
  {} as FormItemContextValue
)

const FormItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const id = React.useId()

  return (
    <FormItemContext.Provider value={{ id }}>
      <div ref={ref} className={cn('mt-3', className)} {...props} />
    </FormItemContext.Provider>
  )
})

FormItem.displayName = 'FormItem'

const FormLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ className, ...props }, ref) => {
  const { formItemId } = useFormField()

  return (
    <Label ref={ref} htmlFor={formItemId} className={className} {...props} />
  )
})

FormLabel.displayName = 'FormLabel'

const FormControl = React.forwardRef<
  React.ElementRef<typeof Slot>,
  React.ComponentPropsWithoutRef<typeof Slot> & { error?: boolean }
>(({ ...props }, ref) => {
  const { error, formItemId, formDescriptionId, formMessageId } = useFormField()

  return (
    <Slot
      ref={ref}
      id={formItemId}
      aria-describedby={
        !error
          ? `${formDescriptionId}`
          : `${formDescriptionId} ${formMessageId}`
      }
      aria-invalid={!!error}
      error={Boolean(error)}
      {...props}
    />
  )
})
FormControl.displayName = 'FormControl'

const FormDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
  const { formDescriptionId } = useFormField()

  return (
    <p
      ref={ref}
      id={formDescriptionId}
      className={cn('text-sm text-muted-foreground', className)}
      {...props}
    />
  )
})
FormDescription.displayName = 'FormDescription'

const FormMessage = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  const { error, formMessageId } = useFormField()
  const body = error ? String(error?.message) : children

  if (!body) {
    return null
  }

  return (
    <InputError ref={ref} id={formMessageId} className={className} {...props}>
      {body}
    </InputError>
  )
})
FormMessage.displayName = 'FormMessage'

const ScrollToFieldError = () => {
  const {
    formState: { errors, isSubmitting },
  } = useFormContext()

  React.useEffect(() => {
    try {
      // Skip if no errors or still submitting
      if (isSubmitting || !errors) {
        return
      }

      // Helper function to get the first error path from nested errors
      const getFirstErrorPath = (
        obj: Record<string, any>,
        path = ''
      ): { path: string; error: FieldError } | null => {
        for (const key in obj) {
          const currentPath = path ? `${path}.${key}` : key

          // Handle FieldError objects
          if (obj[key]?.ref) {
            return { path: currentPath, error: obj[key] as FieldError }
          }

          // Handle array fields
          if (Array.isArray(obj[key])) {
            for (let i = 0; i < obj[key].length; i++) {
              if (obj[key][i]) {
                const arrayResult = getFirstErrorPath(
                  obj[key][i],
                  `${currentPath}.${i}`
                )
                if (arrayResult) {
                  return arrayResult
                }
              }
            }
          }

          // Handle nested objects
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            const nestedResult = getFirstErrorPath(obj[key], currentPath)
            if (nestedResult) {
              return nestedResult
            }
          }
        }
        return null
      }

      const result = getFirstErrorPath(errors)
      if (!result) {
        return
      }

      const { path, error } = result

      // For some reason, the ref is not always an HTMLElement and doesn't have scrollIntoView
      // https://github.com/react-hook-form/react-hook-form/pull/11309
      const element =
        (error?.ref && 'scrollIntoView' in error.ref && error.ref) ||
        document.querySelector(`[id$="-form-item"][name="${path}"]`) ||
        document.querySelector(`[name="${path}"]`)

      if (!element || !(element instanceof HTMLElement)) {
        console.debug('Could not find element for field:', path)
        return
      }

      requestAnimationFrame(() => {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
        if (typeof element.focus === 'function') {
          element.focus({ preventScroll: true })
        }
      })
    } catch (error) {
      // Silently ignore any scrolling errors since this is just a UX enhancement
      console.debug('Error scrolling to form field:', error)
    }
  }, [errors, isSubmitting])

  return null
}

export {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  ScrollToFieldError,
  useFormContext,
  useFormField,
}
